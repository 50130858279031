import React from 'react';

import './LoadingSpinner.css';

const LoadingSpinner = props => {
  return (

    <div className="lds-dual-ring"></div>

  );
};

export default LoadingSpinner;
